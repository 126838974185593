import React from 'react';
import { Link } from 'gatsby';
import { render } from 'react-dom';
import ReactRoundedImage from 'react-rounded-image';
import { SocialIcon } from 'react-social-icons';
import Divider from '@material-ui/core/Divider';
import { Helmet } from 'react-helmet';
import Popup from 'reactjs-popup';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import Jojo from '../1Pics/jojo.jpg';
import Marki from '../1Pics/marki.jpg';
import 'reactjs-popup/dist/index.css';
import PLZ from '../components/PLZ';

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Ablauf</title>
    </Helmet>

    <section id="vorteil" className="pt-20 md:pt-20">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Unsere Vorteile
          </h1>
          <br />
          <p className="text-l">
            <p>
              <strong>1: Abholung innerhalb von 24 Stunden</strong>{' '}
            </p>
            <br />
            <p align="justify">
              Durch die Möglichkeit der freien Wahl von Abholtermin und Abholort gewährleisten wir
              dem Kunden maximale Flexibilität. Wir garantieren eine Abholung innerhalb von 24h und
              ermöglichen Abholungen bereits 5h nach Buchungseingang.{' '}
            </p>{' '}
            <br />
            <p>
              <strong>2: Fixpreisgarantie & Preistransparenz</strong>{' '}
            </p>
            <br />
            <p align="justify">
              Durch eine Echtzeit Kalkulation der Preise (in Abhängigkeit vom Gewicht der
              ausgewählten Abhol-Gegenstände) gewährleisten wir dem Kunden maximale Transparenz
              hinsichtlich anfallender Kosten (keine versteckten Kosten).
            </p>{' '}
            <br />
            <p>
              <strong>3: Online-Buchung & -Bezahlung</strong>{' '}
            </p>
            <br />
            <p align="justify">
              Durch die Abfrage und die Aggregation aller relevanten Datenpunkte (Person, Abholort,
              Termin, Abhol-Gegenstände) lässt sich direkt ein Angebot erstellen und die Buchung
              kann im selben Prozessschritt abgeschlossen werden. Wir wollen uns vollkommen von
              komplizierten, telefonischen und/oder intransparenten Buchungssystemen, sowie den
              damit verbundenen langen Wartezeiten und oft hohen Kosten lösen. Durch Einfachheit,
              Schnelligkeit und Transparenz soll re.cycular die bestehende Lücke im
              Entsorgungssystem schließen und zur Sperrmüllentsorgung in Innenstädten vereinfachen.{' '}
            </p>{' '}
            <br />
            <p>
              <strong>4: CO2-neutrale Abholung</strong>{' '}
            </p>
            <br />
            <p align="justify">
              Durch die Co2-Kompensation, gewährleisten wir dem Kunden eine umweltfreundliche und
              klimaneutrale Abholung. Uns ist bewusst, dass die Welt sauberer und nachhaltger werden
              muss. Deshalb wollen wir mit re.cycular eine einfache Lösung u.a. gegen Wilde
              Müllentsorgung beim Thema Sperrmüll in Großstädten leisten. Langfristig noch viel
              mehr, da wir planen auch die lokal gesourceten Materialien wiederzuverwerten oder
              Gegenstände aufzuwerten, zu reparieren etc. <p />
              <br />
              Langfristig ist das Ziel die Emissionen maximal zu reduzieren und jede mögliche
              Inititative zu ergreifen, um ein grundlegend nachhaltiges Unternehmen aufzubauen. Bis
              dahin ist uns eines klar: Auch unsere Abholungen erzeugen neues Co2. <p />
              <br />
              Um von Anfang an etwas besser zu starten{' '}
              <strong> kompensieren wir das entstandene Co2 der Abholfahrten</strong>, in dem wir
              mit Hilfe von{' '}
              <a href="https://www.myclimate.org/de" target="_blank" rel="noreferrer">
                www.myclimate.org
              </a>{' '}
              in der Höhe des Co2 jedes gefahrenen Kilometers an Klimaschutzprojekte spenden und so
              zumindest finanziell unseren gemeinsamen Beitrag zu leisten. Hier kannst Du mehr
              erfahren:{' '}
              <a href="https://www.myclimate.org/de/informieren" target="_blank" rel="noreferrer">
                {' '}
                Informieren
              </a>
              .{' '}
            </p>{' '}
            <br />
            <p>
              <strong>5: Abholung am Wunschort</strong>{' '}
            </p>
            <br />
            <p align="justify">
              Durch die genaue Angabe der Position (Keller, Stockwerk, Garage, etc.) der
              abzuholenden Gegenstände, können wir uns perfekt auf die Abholung vorbereiten. Wir
              helfen beim Tragen und kümmern uns um eine schnelle und fachgerechte Entsorgung. <p />
              <br />
            </p>
            re.cycular.
            <p className="mt-8">
              <Button>
                <Popup trigger={<button className="button"> Jetzt Buchen </button>} modal>
                  <div>
                    <PLZ />
                  </div>
                </Popup>
              </Button>
            </p>
          </p>
        </div>
      </div>
    </section>
  </Layout>
);
